import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Box,
  useMediaQuery,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ExitToApp,
  AccountCircle,
  SecurityUpdate,
  NotificationsActive,
} from '@mui/icons-material';
import HeaderIcons, { HeaderMenuItem } from './HeaderIcons';
import routes from 'constants/routes';
import { CommonLogos } from 'components/utilities/CommonLogos';
import headerBg from 'assets/images/background-mixedcolors.jpg';
import { useLogout } from 'hooks/useLogout';
import { getTextWithEllipsis } from 'util/getTextWithEllipsis';
import { DropDown } from 'components/utilities/CommonDropDown';
import AppNotifications from 'components/notification/AppNotifications';
import MemoAvatar from 'components/common/images/MemoAvatar';
import GlobalSearchAutoComplete from 'components/common/dataDrivenInputs/GlobalSearchAutoComplete';
import DrawerMenu from 'components/common/DrawerMenu';
import Sidebar from 'components/layouts/SettingsSidebar';
import { useSimpleUser } from 'services/user/useActiveUser';
import { MessagingIconButton } from 'components/messaging/MessagingIconButton';
import { usePwaContext } from 'context/PwaContext';
import { useHeaderTabs } from './useHeaderTabs';
import {
  usePromptToActivatePushNotifications,
  useShouldPromptViaUserActionForPushNotifications,
} from 'services/notifications/pushNotifications';
import ScrollToTop from 'components/utilities/ScrollToTop';

/**
 * Styles logic for this component.
 */
const useHeaderStyles = makeStyles((theme) => ({
  headerLogo: {
    [theme.breakpoints.down('sm')]: {
      width: '30px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '60px',
    },
    '& img': {
      width: '100%',
    },
  },
  headerBg: {
    backgroundImage: `url(${headerBg})`,
    width: '100%',
    height: 'auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

/**
 * Styles logic for this component.
 */
const useStyles = makeStyles((theme) => ({
  imgUser: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  dropdownDownCustom: {
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50px',
      height: '50px',
    },
    objectFit: 'contain',
    '& button': {
      padding: '12px',
      '& svg': {
        fill: '#fff',
      },
      '&:hover': {
        '& svg': {
          fill: '#000',
        },
      },
    },
  },
  dropdownIcon: {},
  userAvatar: {
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      margin: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '50px',
      margin: '10px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    position: 'relative',
    overflow: 'hidden',
  },
  userProfileWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& a': {
      color: '#fff',
    },
  },
  unreadBadge: {
    backgroundColor: theme.palette.warning.dark,
    borderRadius: theme.spacing(1),
    height: theme.spacing(1),
    width: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(1.4),
    right: theme.spacing(1.4),
  },
}));

function EmptyHeader({
  children,
  showSearch = false,
}: {
  showSearch?: boolean;
  children?: React.ReactNode;
}) {
  const history = useHistory();
  const classes = useHeaderStyles();
  return (
    <AppBar position="relative" className={classes.headerBg}>
      <Toolbar>
        <Box
          px={0}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" alignItems="center" flexGrow={0.5}>
            <Box className={classes.headerLogo}>
              <CommonLogos
                altLabel="HeaderLogo"
                type="FaviconWhite"
                onClick={() => history.push(routes.HOME)}
              />
            </Box>
            {showSearch && (
              <Box ml={2} width="100%">
                <GlobalSearchAutoComplete />
              </Box>
            )}
          </Box>
          {children}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

function usePromptForPush() {
  const classes = useStyles();
  const shouldPrompt = useShouldPromptViaUserActionForPushNotifications();
  const promptForNotifications = usePromptToActivatePushNotifications();
  const shouldPromptForPush = shouldPrompt();

  return useMemo(() => {
    if (shouldPromptForPush) {
      return [
        {
          title: 'Subscribe to Notifications',
          icon: <NotificationsActive className={classes.dropdownIcon} />,
          handleItemClick: promptForNotifications,
        },
      ];
    }
    return [];
  }, [shouldPromptForPush, classes.dropdownIcon, promptForNotifications]);
}

function useDropdownItems() {
  const classes = useStyles();
  const { installPrompt, canInstall, isStandalone } = usePwaContext();
  const { logout } = useLogout();
  const promptItem = usePromptForPush();

  return useMemo(() => {
    return [
      ...(canInstall && !isStandalone
        ? [
            {
              title: 'Download App',
              icon: <SecurityUpdate className={classes.dropdownIcon} />,
              handleItemClick: installPrompt,
            },
          ]
        : []),
      ...promptItem,
      {
        title: 'Profile',
        icon: <AccountCircle className={classes.dropdownIcon} />,
        link: routes.PROFILE,
        isAdminFunctionality: false,
      },
      {
        title: 'Logout',
        icon: <ExitToApp className={classes.dropdownIcon} />,
        handleItemClick: () => {
          logout();
        },
      },
    ] satisfies HeaderMenuItem[];
  }, [
    canInstall,
    isStandalone,
    classes.dropdownIcon,
    installPrompt,
    promptItem,
    logout,
  ]);
}

export default function Header(): JSX.Element {
  const history = useHistory();
  const simpleUser = useSimpleUser();

  const classes = useStyles();
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const headerTabs = useHeaderTabs(simpleUser.id);
  const dropdownItems = useDropdownItems();
  const promptItem = usePromptForPush();

  /**
   * This function handles page nav
   */
  const handleHeaderDropdown = (item: HeaderMenuItem) => {
    if ('handleItemClick' in item && item.handleItemClick) {
      item.handleItemClick();
      return;
    }

    if ('link' in item && item.link) {
      history.push(item.link);
      return;
    }
  };

  if (simpleUser == null) {
    // this is a non-ideal scenario - either we're loading the app, or the user needs to re-auth
    return <EmptyHeader />;
  }

  return (
    <EmptyHeader showSearch={true}>
      <>
        {!isMobile && <HeaderIcons tabs={headerTabs} />}

        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <ScrollToTop />
          </Box>
          <Box display={{ xs: 'none', sm: 'block' }}>
            <Box className={classes.userProfileWrapper}>
              <Box className={classes.userAvatar}>
                {simpleUser.avatar ? (
                  <MemoAvatar
                    src={simpleUser.avatar}
                    alt="user-logo"
                    avatarSize="large"
                  />
                ) : (
                  <Avatar />
                )}
              </Box>
              <Link to={routes.PROFILE}>
                <Typography variant="body1">
                  {getTextWithEllipsis({
                    text: simpleUser.name ?? '',
                    limit: 30,
                  })}
                </Typography>
              </Link>
            </Box>
          </Box>
          <Box
            className={`${classes.userAvatar} ${classes.dropdownDownCustom}`}
          >
            {isMobile || isTablet ? (
              <DrawerMenu>
                <Sidebar additionalItems={promptItem} />
              </DrawerMenu>
            ) : (
              <DropDown
                as="arrow"
                items={dropdownItems}
                onItemClick={(item) => handleHeaderDropdown(item)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              />
            )}
          </Box>
          {<MessagingIconButton />}
          <AppNotifications />
        </Box>
      </>
    </EmptyHeader>
  );
}
