import { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import { queries } from 'services/notifications';
import { useLogoutOneSignalUser } from './oneSignal/useLogoutOneSignalUser';
import { useLoginOneSignalUser } from './oneSignal/useLoginOneSignalUser';
import { PublicUserId } from 'src/services/userService/makePublicUserId';
import { browserIsSafari, userIsOnIosDevice } from '../../util/navigator';
import OneSignal from 'react-onesignal';
import { useRoundtableAuth0 } from '../auth/hooks/useRoundtableAuth0';
import { usePromptForPushNotifications } from './oneSignal/usePromptForPushNotifications';

export function useLogoutUserFromPushNotifications() {
  return useLogoutOneSignalUser();
}

export function usePromptToActivatePushNotifications() {
  return usePromptForPushNotifications();
}

export function useLoginUserForPushNotifications() {
  const loginToOneSignal = useLoginOneSignalUser();

  const { isAuthenticated } = useAuth0();
  return useCallback(
    async (pubicUserId: PublicUserId) => {
      if (!isAuthenticated) return;

      await loginToOneSignal(pubicUserId);
    },
    [isAuthenticated, loginToOneSignal]
  );
}

/**
 * @deprecated - noop for now, I think one signal handles this on the backend
 * todo: remove this once we confirm its not needed
 */
export const useSetUserInterestOnPusher = (): {
  updateUserInterests: () => void;
} => {
  /**
   * fetches and sets interests on pusher, avoids setting any state to prevent un-necessary re-renders
   */
  const updateUserInterests = useCallback(async () => {
    // const interests = await queries.fetchUserInterests();
    // if (interests.data.data.length === 0) {
    //   return;
    // }
    // const allDefaultInterest =
    //   config.env === 'development'
    //     ? [...(defaultInterests ?? []), 'debug-test']
    //     : defaultInterests ?? [];
    //
    // await client.setDeviceInterests([
    //   ...allDefaultInterest,
    //   ...interests.data.data,
    // ]);
  }, []);

  return {
    updateUserInterests,
  };
};

export function useShouldPromptViaUserActionForPushNotifications() {
  const { isAuthenticated } = useRoundtableAuth0();
  return useCallback(() => {
    if (OneSignal.Notifications.permission) {
      // we already have permission - don't prompt
      return false;
    }

    return (
      // for Ios or Safari, the user needs to be prompted to subscribe to notifications
      (browserIsSafari() || userIsOnIosDevice()) &&
      isAuthenticated &&
      OneSignal.Notifications.isPushSupported()
    );
  }, [isAuthenticated]);
}

export function usePromptNonIosDevicesForPush() {
  const prompt = usePromptToActivatePushNotifications();
  const { isAuthenticated } = useRoundtableAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (userIsOnIosDevice()) {
      return;
    }
    if (browserIsSafari()) {
      return;
    }
    if (OneSignal.Notifications.permission) {
      return;
    }

    if (!OneSignal.Notifications.isPushSupported()) {
      return;
    }

    prompt();
  }, [isAuthenticated, prompt]);
}
